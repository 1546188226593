import React from 'react'; 
import classes from './Input.module.css';

const Input = (props) => {
    let inputElement = null; 
    const inputClasses = [classes.InputElement]; 

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid); 
    }
    
    switch (props.elementType) {
    case ('input'):
        inputElement = (
            <input 
                className={inputClasses.join(' ')} 
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
            /> 
        );
        break;

    case ('textarea'):
        inputElement = (
            <textarea 
                className={inputClasses.join(' ')} 
                {...props.elementConfig} 
                value={props.value}
                onChange={props.changed}
            />
        );
        break;

    case ('select'):
        inputElement = (
            <select 
                className={inputClasses.join(' ')} 
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
            >      
                {props.elementConfig.options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.displayValue}
                    </option>
                ))}
            </select>
        );
        break;

    default:
        inputElement = (
            <input 
                className={inputClasses.join(' ')} 
                {...props.elementConfig}
                value={props.value}
            />
        );
    }

    let validationError = null;
    if (props.invalid && props.shouldValidate && props.touched) {
        validationError = (
            <p className = {classes.ValidationError}>
                {props.customErrMsg ? props.customErrMsg : "Please enter a valid " + props.valueType.toLowerCase() + "."}
            </p>
        );
    }

    return(
        <div className={classes.Input}>
            {inputElement}
            {validationError}
        </div>
    ); 
    
};

export default Input; 
