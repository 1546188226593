export const BUY_SPIN_START = 'BUY_SPIN_START'; 
export const BUY_SPIN_SUCCESS = 'BUY_SPIN_SUCCESS'; 
export const BUY_SPIN_FAIL = 'BUY_SPIN_FAIL'; 
export const RESET_BUY_SPIN_ERROR = 'RESET_BUY_SPIN_ERROR'; 
export const GET_FREE_SP_START = 'GET_FREE_SP_START';
export const GET_FREE_SP_SUCCESS = 'GET_FREE_SP_SUCCESS';
export const GET_FREE_SP_FAIL = 'GET_FREE_SP_FAIL'; 
export const RESET_GET_FREE_SP_ERROR = 'RESET_GET_FREE_SP_ERROR';
export const ITEM_UNBOXED = 'ITEM_UNBOXED'; 

export const FETCH_MARKET_START = 'FETCH_MARKET_START'; 
export const FETCH_MARKET_SUCCESS = 'FETCH_MARKET_SUCCESS'; 
export const FETCH_MARKET_FAIL = 'FETCH_MARKET_FAIL'; 
export const BUY_ITEM_START = 'BUY_ITEM_START';
export const BUY_ITEM_SUCCESS = 'BUY_ITEM_SUCCESS';
export const BUY_ITEM_FAIL = 'BUY_ITEM_FAIL';
export const RESET_BUY_ITEM_ERROR = 'RESET_BUY_ITEM_ERROR';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export const RESET_SELECTED_ITEM = 'RESET_SELECTED_ITEM';
export const ITEM_LISTED = 'ITEM_LISTED'; 

export const FETCH_INVENTORY_START = 'FETCH_INVENTORY_START'; 
export const FETCH_INVENTORY_SUCCESS = 'FETCH_INVENTORY_SUCCESS'; 
export const FETCH_INVENTORY_FAIL = 'FETCH_INVENTORY_FAIL'; 
export const LIST_ITEM_START = 'LIST_ITEM_START';
export const LIST_ITEM_SUCCESS = 'LIST_ITEM_SUCCESS';
export const LIST_ITEM_FAIL = 'LIST_ITEM_FAIL';
export const RESET_LIST_ITEM_ERROR = 'RESET_LIST_ITEM_ERROR';

export const FETCH_PROFILE_START = 'FETCH_PROFILE_START'; 
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS'; 
export const FETCH_PROFILE_FAIL = 'FETCH_PROFILE_FAIL'; 

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const RESET_AUTH_ERROR = 'RESET_AUTH_ERROR';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS'; 
export const LOG_OUT_FAIL = 'LOG_OUT_FAIL'; 
export const RESET_LOG_OUT_ATTEMPT = 'RESET_LOG_OUT_ATTEMPT';
export const RESET_LOG_OUT_ERROR = 'RESET_LOG_OUT_ERROR';
export const AUTO_LOG_IN_SUCCESS = 'AUTO_LOG_IN_SUCCESS';
export const AUTO_LOG_IN_FAIL = 'AUTO_LOG_IN_FAIL';
export const CHANGE_SP = 'CHANGE_SP'; 
